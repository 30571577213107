.page-home {
  .horizon {
    background: url('/img/background.jpg') 50% 100% no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: table-cell;
    height: 100vh;
    overflow: hidden;
    padding-top: 10vh;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 100vw;
  }

  .mountains {
    background: url('/img/mountains.png') 50% 100% no-repeat;
    background-size: contain;
    bottom: 0;
    height: 25vw;
    left: 0;
    position: fixed;
    width: 100vw;
    z-index: 50;
  }

  .grid {
    background: url('/img/grid.png') 50% 100% no-repeat;
    background-size: cover;
    height: 25vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .triangle {
    background: url('/img/triangle.svg') 50% 50% no-repeat;
    background-size: contain;
    height: 80vh;
    left: 5vw;
    position: absolute;
    top: 5vh;
    width: 90vw;
    z-index: 10;
  }

  h1,
  h2,
  h3 {
    display: block;
    line-height: 1;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
  }

  h1 {
    font-size: 10vmin;
    font-weight: 300;
    position: relative;
    z-index: 30;

    &::before,
    &::after {
      background-color: $white;
      content: ' ';
      display: inline-block;
      height: 2px;
      margin: 0 4vmin;
      position: relative;
      top: -3vmin;
      width: 10vmin;
    }
  }

  h2 {
    color: $pink;
    font-size: 15vmin;
    z-index: 10;
  }

  h3 {
    color: $red;
    display: inline-block;
    font-family: 'Lazer84';
    font-size: 16vmin;
    height: 33vmin;
    position: relative;
    text-shadow: .4vmin .4vmin 0 $grey-dark;
    top: -21vmin;
    width: 65vmin;
    z-index: 20;

    span {
      $dimensions: 95vmin;
      @include rotated-text(7, 45deg, -36deg);

      display: inline-block;
      height: $dimensions;
      left: 0;
      position: absolute;
      top: 0;
      transform-origin: bottom center;
      width: $dimensions;
    }

    &::after {
      background: url('/img/underline.svg') 50% 50% no-repeat;
      background-size: contain;
      bottom: 3vmin;
      content: ' ';
      display: block;
      height: 10vmin;
      left: -17vmin;
      margin-left: 50%;
      position: absolute;
      transform: rotate(-10deg);
      width: 45vmin;
    }
  }

  .contact {
    background: url('/img/ibm.jpg') 50% 50% no-repeat;
    background-size: cover;
    height: 80vh;
    padding: 30vh 0 0;
    text-align: center;
    width: 100%;

    a {
      font-family: $cursive-font-family;

      &:hover {
        color: $green;
        text-shadow: 1px 1px 0 $black;
      }
    }
  }
}
