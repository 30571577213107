// Core Includes and Settings
@import 'bourbon/bourbon';
@import 'variables';
@import 'fonts';
@import 'mixins';

// Components
// TODO

// Sections
@import 'sections/header';
@import 'sections/footer';

// Pages
@import 'pages/default';
@import 'pages/index';
