// Fonts
$base-font-family: 'Roboto Condensed', Helvetica, Arial, Sans-Serif;
$base-font-size: 16px;

$cursive-font-family: 'Press Start 2P', cursive;

// Colours
$white: #fefefe;
$grey: #414141;
$grey-dark: #282323;
$black: #000;

$teal: #b6d5ed;
$green: #18ed15;

$red: #db2727;
$purple: #1d0b35;
$pink: #ff46f9;
$hot-pink: #ff00fc;

// Layout
$gutter: 30px;
