// Global Styling
html,
body {
  background-color: $black;
  color: $white;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

a {
  color: $white;
  text-decoration: none;
  transition: color .15s ease-in-out;

  &:hover {
    color: $teal;
  }
}
